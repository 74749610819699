import React, { Component } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';

const ContactMap = () => {
    return (
        <div>
            <p>Leave us a message or feedback here, we will contact you back.</p>
        </div>
    )
}

class Elements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendText: 'Send',
            sending: false,
            sendFailed: false,
            allFilled: null,
            firstName: '',
            lastName: '',
            number: '',
            email: '',
            message: '',
            token: '',
        }
    }

    componentDidMount() {
        window.grecaptcha.execute('6LdyGboUAAAAAJn2uv6NfDceQd7WQG4evjWsQqb2', {action: 'contact'})
        .then((token) => this.setState({ token }));
    }

    isAllFilled = () => this.state.firstName && this.state.lastName && this.state.email && this.state.message;
    handleChange = (event) => this.setState({[event.target.name]: event.target.value, allFilled: null, sendFailed: false });
    handleSubmit = (event) => {
        event.preventDefault();
        if(this.isAllFilled()) {
            this.setState({sending: true, allFilled: null});
            axios.post('https://rwye4sau55.execute-api.us-east-1.amazonaws.com/dev/handler', {
                name: `${this.state.firstName} ${this.state.lastName}`,
                email: this.state.email,
                number: this.state.number,
                message: this.state.message,
                token: this.state.token,
            })
            .then(() => navigate('thanks', {'state': { name: this.state.firstName }}))
            .catch(() => this.setState({sendFailed: true, sending: false}));
        } else {
            this.setState({allFilled: false})
        }
    }

    render() {
        const { sendText, sending, allFilled, sendFailed } = this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label for="firstName">First Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        placeholder="First name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label for="lastName">Last Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label for="number">Phone Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="number"
                        placeholder="Phone number"
                        name="number"
                        value={this.state.number}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label for="email">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email address"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label for="message">Message</label>
                    <textarea
                        type="text"
                        className="form-control"
                        rows="5"
                        id="message"
                        placeholder="Message..."
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-white btn-lg"
                >
                    {sending && <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"> </span>}
                    {sendText}
              </button>
              {allFilled === false && <p className="text-danger">Please fill all fields!</p>}
              {sendFailed && <p className="text-danger">Oops... failed to send the message. Please try again later!</p>}
            </form>
        )
    }
}

const ContactForm = () => {
    return (
        <>
            <h4>Contact Us</h4>
            <ContactMap />
            <Elements />
        </>
    )
}

export default ContactForm;