import React from 'react';
import Layout from '../components/layout';
import ContactForm from '../components/ContactForm';

const Contact = () => {
    return (
        <Layout title="Contact Us">
            <div className="container">
                <ContactForm />
            </div>
        </Layout>
    )
}

export default Contact;